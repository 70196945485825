
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        










































.hub-tv-carte,
[class*='hub-tv-carte--'] {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include mq(m) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  @include mq(xl) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.hub-tv-carte__picture-outer {
  position: relative;
  width: 100%;
  max-width: 45rem;
  height: 25rem;
  margin: $spacing auto;

  @include mq($until: l) {
    order: -1;
  }

  @include mq(l) {
    margin: 0 auto;
  }
}

.hub-tv-carte__picture {
  @include image-fit;

  @include mq(m) {
    object-position: 0% 50%;
  }
}

.hub-tv-carte-inner {
  display: flex;
  flex-direction: column;
  @include mq(l) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.hub-tv-carte__infos {
  z-index: layer(lower);

  @include mq(m) {
    width: col(6);
  }
}
