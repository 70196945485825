
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



























































































.pagination {
  width: max-content;
  margin: 15.5rem auto;
}

ul {
  display: flex;
  padding: 0;
  list-style: none;
  text-align: center;
}

li {
  margin: 0 2.8rem;
  font-size: 2.4rem;
  font-weight: 700;
}

a {
  text-decoration: none;

  &.router-link-active {
    position: relative;

    &::after {
      @include center-xy();

      content: '';
      width: 4rem;
      height: 4rem;
      border: 3px solid $c-gray-darkest;
      border-radius: 50%;
    }
  }
}
