
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






















































.hub-tv-box-evasion,
[class*='hub-tv-box-evasion--'] {
  position: relative;
  margin-top: 10rem;
}

.hub-tv-box-evasion__picture {
  @include image-fit;

  @include mq(m) {
    object-position: 0% 50%;
  }
}

.hub-tv-box-evasion-inner {
  @extend %color-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq(m) {
    align-items: stretch;
  }
}

.hub-tv-box-evasion__infos {
  z-index: layer(lower);
  margin: 3rem 0 50%;

  @include mq(m) {
    width: col(6);
    margin: 5rem 0 7rem;
  }

  @include mq(xl) {
    width: col(4);
  }
}

.hub-tv-box-evasion__infos__link {
  color: $c-pink-medium;
}
