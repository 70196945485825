
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




























































































.dropdown-outer {
  position: relative;
  z-index: 20;
  height: 5rem;
  margin-bottom: $spacing * 2;
}

.dropdown {
  @extend %box-shadow;

  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  background-color: $white;
  border-radius: 4px;
}

.dropdown__item,
[class*='dropdown__item--'] {
  font-size: 0.9em;
  line-height: 3.2rem;
  text-decoration: none;
  cursor: pointer;

  &:first-child {
    padding: 0 $spacing;
    font-size: 1em;
    line-height: 5rem;
  }
}

.dropdown__item--trigger {
  color: $c-blue-darker;
}

.dropdown__item__link,
[class*='dropdown__item__link--'] {
  display: block;
  padding: $spacing / 2 $spacing;
  line-height: 3.2rem;
  text-decoration: none;
  transition: background-color 0.3s $ease-out-quad;

  &:hover {
    background-color: darken($white, 5%);
  }
}

.dropdown__icon {
  position: absolute;
  top: $spacing / 1.2;
  right: $spacing;
  width: 1.8rem;
  height: 1.8rem;
  fill: $c-pink-medium;
  pointer-events: none;
  transform-origin: center center;
  transition: transform 0.2s ease-out;

  .is-open & {
    transform: rotate(180deg);
  }
}
